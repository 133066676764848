// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/styles/Intro.css */

.Intro_intro__ufcM3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.Intro_instructions__JN388 {
    text-align: left;
}

ul {
    text-align: left;
}

button {
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: max-content;
}

button:hover {
    background-color: #0056b3;
}`, "",{"version":3,"sources":["webpack://./src/styles/Intro.module.css"],"names":[],"mappings":"AAAA,yBAAyB;;AAEzB;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,WAAW;IACX,yBAAyB;IACzB,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,iCAAiC;IACjC,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["/* src/styles/Intro.css */\n\n.intro {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    padding: 20px;\n    max-width: 1200px;\n    margin: 0 auto;\n}\n\n.instructions {\n    text-align: left;\n}\n\nul {\n    text-align: left;\n}\n\nbutton {\n    padding: 10px 20px;\n    font-size: 1em;\n    color: #fff;\n    background-color: #007bff;\n    border: none;\n    border-radius: 10px;\n    cursor: pointer;\n    transition: background-color 0.3s;\n    width: max-content;\n}\n\nbutton:hover {\n    background-color: #0056b3;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"intro": `Intro_intro__ufcM3`,
	"instructions": `Intro_instructions__JN388`
};
export default ___CSS_LOADER_EXPORT___;
