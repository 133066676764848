// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/styles/PersonaPage.css */

.PersonaPage_persona-page__Ncu62 {
    margin: 50px auto;
    background-color: #fff;
    border-radius: 10px;
    text-align: center;
    width: 85%;
}

.PersonaPage_persona-page__Ncu62 h1 {
    margin-bottom: 50px;
}

.PersonaPage_persona-page__Ncu62 h2 {
    margin-bottom: 20px;
    color: #333;
}

.PersonaPage_chart-container__1jxTW {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    flex-wrap: wrap;
}

.PersonaPage_radar-charts__4IkKf {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr)); /* Ajuste les colonnes automatiquement */
    gap: 20px; /* Espace entre les éléments */
    width: 100%;
    margin-top: 20px;
    justify-items: center; /* Centre les éléments horizontalement */
    align-items: center; /* Centre les éléments verticalement */
}

.PersonaPage_radar-chart__r\\+r9d {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
    width: 500px;
    height: 500px;
}

.PersonaPage_button__YuKtF {
    margin: 20px 0;
}`, "",{"version":3,"sources":["webpack://./src/styles/PersonaPage.module.css"],"names":[],"mappings":"AAAA,+BAA+B;;AAE/B;IACI,iBAAiB;IACjB,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,2DAA2D,EAAE,wCAAwC;IACrG,SAAS,EAAE,8BAA8B;IACzC,WAAW;IACX,gBAAgB;IAChB,qBAAqB,EAAE,wCAAwC;IAC/D,mBAAmB,EAAE,sCAAsC;AAC/D;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,cAAc;AAClB","sourcesContent":["/* src/styles/PersonaPage.css */\n\n.persona-page {\n    margin: 50px auto;\n    background-color: #fff;\n    border-radius: 10px;\n    text-align: center;\n    width: 85%;\n}\n\n.persona-page h1 {\n    margin-bottom: 50px;\n}\n\n.persona-page h2 {\n    margin-bottom: 20px;\n    color: #333;\n}\n\n.chart-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin-top: 50px;\n    flex-wrap: wrap;\n}\n\n.radar-charts {\n    display: grid;\n    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr)); /* Ajuste les colonnes automatiquement */\n    gap: 20px; /* Espace entre les éléments */\n    width: 100%;\n    margin-top: 20px;\n    justify-items: center; /* Centre les éléments horizontalement */\n    align-items: center; /* Centre les éléments verticalement */\n}\n\n.radar-chart {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    margin: 20px;\n    width: 500px;\n    height: 500px;\n}\n\n.button {\n    margin: 20px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"persona-page": `PersonaPage_persona-page__Ncu62`,
	"chart-container": `PersonaPage_chart-container__1jxTW`,
	"radar-charts": `PersonaPage_radar-charts__4IkKf`,
	"radar-chart": `PersonaPage_radar-chart__r+r9d`,
	"button": `PersonaPage_button__YuKtF`
};
export default ___CSS_LOADER_EXPORT___;
