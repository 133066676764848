// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/styles/Notification.css */
.Notification_notification__rwHPz {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 300px;
    color: white;
    opacity: 1;
    transition: opacity 0.3s ease-out;
}

.Notification_notification__rwHPz p {
    margin: 0;
    flex-grow: 1;
    color: white;
}

.Notification_notification__rwHPz .Notification_close-btn__01SWI {
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.Notification_notification__rwHPz.Notification_success__TPEKk {
    background-color: #4CAF50;
    /* Green for success */
}

.Notification_notification__rwHPz.Notification_error__tefA2 {
    background-color: #f44336;
    /* Red for error */
}


/* Fade-in and fade-out classes */
.Notification_fade-in__IuLPU {
    opacity: 1;
    transition: opacity 0.3s ease-in;
}

.Notification_fade-out__a5v3o {
    opacity: 0;
    transition: opacity 0.3s ease-in;
}

/* Close button */
.Notification_close-btn__01SWI {
    position: absolute;
    top: 5px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 16px;
    cursor: pointer;
}

.Notification_close-btn__01SWI:hover {
    color: #333;
}`, "",{"version":3,"sources":["webpack://./src/styles/Notification.module.css"],"names":[],"mappings":"AAAA,gCAAgC;AAChC;IACI,eAAe;IACf,YAAY;IACZ,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,wCAAwC;IACxC,aAAa;IACb,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,UAAU;IACV,iCAAiC;AACrC;;AAEA;IACI,SAAS;IACT,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;AACtB;;;AAGA,iCAAiC;AACjC;IACI,UAAU;IACV,gCAAgC;AACpC;;AAEA;IACI,UAAU;IACV,gCAAgC;AACpC;;AAEA,iBAAiB;AACjB;IACI,kBAAkB;IAClB,QAAQ;IACR,WAAW;IACX,uBAAuB;IACvB,YAAY;IACZ,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,WAAW;AACf","sourcesContent":["/* src/styles/Notification.css */\n.notification {\n    position: fixed;\n    bottom: 20px;\n    right: 20px;\n    padding: 15px;\n    border-radius: 10px;\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);\n    z-index: 1000;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 300px;\n    color: white;\n    opacity: 1;\n    transition: opacity 0.3s ease-out;\n}\n\n.notification p {\n    margin: 0;\n    flex-grow: 1;\n    color: white;\n}\n\n.notification .close-btn {\n    background: none;\n    border: none;\n    color: white;\n    font-size: 16px;\n    cursor: pointer;\n}\n\n.notification.success {\n    background-color: #4CAF50;\n    /* Green for success */\n}\n\n.notification.error {\n    background-color: #f44336;\n    /* Red for error */\n}\n\n\n/* Fade-in and fade-out classes */\n.fade-in {\n    opacity: 1;\n    transition: opacity 0.3s ease-in;\n}\n\n.fade-out {\n    opacity: 0;\n    transition: opacity 0.3s ease-in;\n}\n\n/* Close button */\n.close-btn {\n    position: absolute;\n    top: 5px;\n    right: 10px;\n    background: transparent;\n    border: none;\n    font-size: 16px;\n    cursor: pointer;\n}\n\n.close-btn:hover {\n    color: #333;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notification": `Notification_notification__rwHPz`,
	"close-btn": `Notification_close-btn__01SWI`,
	"success": `Notification_success__TPEKk`,
	"error": `Notification_error__tefA2`,
	"fade-in": `Notification_fade-in__IuLPU`,
	"fade-out": `Notification_fade-out__a5v3o`
};
export default ___CSS_LOADER_EXPORT___;
