// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tabs_tab__GYIS5 {
  width: 100%;
  padding: 25px;
  font-family: sans-serif;
  color: #444;
}

.Tabs_tab-ul__S3fEK {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  -webkit-padding-start: 0px;
}

.Tabs_tab-li__8dgwu{
  display: inline-block;
  margin: 10px;
  padding: 10px;
  border-bottom: 2px solid #dddddd;
  transition: all 0.5s;
  font-family: Lato, sans-serif;
  font-weight: 300;
  cursor: pointer;
  color: #6b6b6b;
}

.Tabs_tab-li__8dgwu:hover{
  display: inline-block;
  margin: 10px;
  padding: 10px;
  border-bottom: 2px solid #5c5c5c;
  transition: all 0.5s;
  font-family: Lato, sans-serif;
  font-weight: 300;
  cursor: pointer;
  color: #5c5b5b;
}

.Tabs_tab-li-selected__vrSrR {
  border-bottom: 2px solid #218CFF;
  display: inline-block;
  margin: 10px;
  padding: 10px;
  transition: all 0.5s;
  font-family: Lato, sans-serif;
  font-weight: 300;
  cursor: pointer;
  color: #218CFF;
}`, "",{"version":3,"sources":["webpack://./src/styles/Tabs.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,UAAU;EACV,gBAAgB;EAChB,wBAAwB;EACxB,uBAAuB;EACvB,yBAAyB;EACzB,uBAAuB;EACvB,0BAA0B;AAC5B;;AAEA;EACE,qBAAqB;EACrB,YAAY;EACZ,aAAa;EACb,gCAAgC;EAChC,oBAAoB;EACpB,6BAA6B;EAC7B,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB,YAAY;EACZ,aAAa;EACb,gCAAgC;EAChC,oBAAoB;EACpB,6BAA6B;EAC7B,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,gCAAgC;EAChC,qBAAqB;EACrB,YAAY;EACZ,aAAa;EACb,oBAAoB;EACpB,6BAA6B;EAC7B,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB","sourcesContent":[".tab {\n  width: 100%;\n  padding: 25px;\n  font-family: sans-serif;\n  color: #444;\n}\n\n.tab-ul {\n  list-style: none;\n  padding: 0;\n  margin-bottom: 0;\n  -webkit-margin-before: 0;\n  -webkit-margin-after: 0;\n  -webkit-margin-start: 0px;\n  -webkit-margin-end: 0px;\n  -webkit-padding-start: 0px;\n}\n\n.tab-li{\n  display: inline-block;\n  margin: 10px;\n  padding: 10px;\n  border-bottom: 2px solid #dddddd;\n  transition: all 0.5s;\n  font-family: Lato, sans-serif;\n  font-weight: 300;\n  cursor: pointer;\n  color: #6b6b6b;\n}\n\n.tab-li:hover{\n  display: inline-block;\n  margin: 10px;\n  padding: 10px;\n  border-bottom: 2px solid #5c5c5c;\n  transition: all 0.5s;\n  font-family: Lato, sans-serif;\n  font-weight: 300;\n  cursor: pointer;\n  color: #5c5b5b;\n}\n\n.tab-li-selected {\n  border-bottom: 2px solid #218CFF;\n  display: inline-block;\n  margin: 10px;\n  padding: 10px;\n  transition: all 0.5s;\n  font-family: Lato, sans-serif;\n  font-weight: 300;\n  cursor: pointer;\n  color: #218CFF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tab": `Tabs_tab__GYIS5`,
	"tab-ul": `Tabs_tab-ul__S3fEK`,
	"tab-li": `Tabs_tab-li__8dgwu`,
	"tab-li-selected": `Tabs_tab-li-selected__vrSrR`
};
export default ___CSS_LOADER_EXPORT___;
