// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Footer styles */

.Footer_footer__u5gg\\+ {
    background-color: #696969;
    padding: 20px;
    text-align: center;
}

.Footer_footer__u5gg\\+ p {
    color: white;
    margin: 0;
}

.Footer_footer__u5gg\\+ a {
    text-decoration: none;
}

.Footer_footer-links__jY3HP {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    gap: 10px;
    color: white;
}

.Footer_footer-links__jY3HP a {
    color: white;
}

.Footer_footer__u5gg\\+ a:hover {
    text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/styles/Footer.module.css"],"names":[],"mappings":"AAAA,kBAAkB;;AAElB;IACI,yBAAyB;IACzB,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,SAAS;AACb;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,gBAAgB;IAChB,SAAS;IACT,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":["/* Footer styles */\n\n.footer {\n    background-color: #696969;\n    padding: 20px;\n    text-align: center;\n}\n\n.footer p {\n    color: white;\n    margin: 0;\n}\n\n.footer a {\n    text-decoration: none;\n}\n\n.footer-links {\n    display: flex;\n    justify-content: center;\n    margin-top: 10px;\n    gap: 10px;\n    color: white;\n}\n\n.footer-links a {\n    color: white;\n}\n\n.footer a:hover {\n    text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `Footer_footer__u5gg+`,
	"footer-links": `Footer_footer-links__jY3HP`
};
export default ___CSS_LOADER_EXPORT___;
