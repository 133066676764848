// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ViewJob_view-job-container__F9j3l {
    padding: 20px;
    max-width: 80%;
    margin: 0 auto;
}

.ViewJob_toggle-button__caD4J {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.ViewJob_toggle-button__caD4J button {
    padding: 10px 20px;
    border: none;
    background-color: #f0f0f0;
    cursor: pointer;
    transition: background-color 0.3s;
}

.ViewJob_toggle-button__caD4J button.ViewJob_active__7mTou {
    background-color: #218CFF;
    color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/styles/ViewJob.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,cAAc;IACd,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,yBAAyB;IACzB,eAAe;IACf,iCAAiC;AACrC;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf","sourcesContent":[".view-job-container {\n    padding: 20px;\n    max-width: 80%;\n    margin: 0 auto;\n}\n\n.toggle-button {\n    display: flex;\n    justify-content: center;\n    margin-bottom: 20px;\n}\n\n.toggle-button button {\n    padding: 10px 20px;\n    border: none;\n    background-color: #f0f0f0;\n    cursor: pointer;\n    transition: background-color 0.3s;\n}\n\n.toggle-button button.active {\n    background-color: #218CFF;\n    color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"view-job-container": `ViewJob_view-job-container__F9j3l`,
	"toggle-button": `ViewJob_toggle-button__caD4J`,
	"active": `ViewJob_active__7mTou`
};
export default ___CSS_LOADER_EXPORT___;
