// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TestSwitcher_test-switcher__BbEuC {
    display: flex;
    justify-content: center;
    gap: 5px;
    width: 100%;
    margin-bottom: 20px;
    background-color: #f0f0f0;
    border-radius: 25px;
    padding: 5px;
    max-width: 500px;
    margin: 20px auto; /* Center the toggle button on the page */
}

.TestSwitcher_test-button__NCt7d {
    flex: 1 1;
    padding: 10px 20px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 16px;
    color: #555;
    transition: background-color 0.3s, color 0.3s;
    border-radius: 20px;
}

.TestSwitcher_test-button__NCt7d:hover {
    background-color: #ddd;
    
}

.TestSwitcher_test-button-active__r68GJ {
    flex: 1 1;
    padding: 10px 20px;
    border: none;
    background-color: #218CFF;
    cursor: pointer;
    font-size: 16px;
    color: #ffffff;
    transition: background-color 0.3s, color 0.3s;
    border-radius: 20px;
}`, "",{"version":3,"sources":["webpack://./src/styles/TestSwitcher.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,QAAQ;IACR,WAAW;IACX,mBAAmB;IACnB,yBAAyB;IACzB,mBAAmB;IACnB,YAAY;IACZ,gBAAgB;IAChB,iBAAiB,EAAE,yCAAyC;AAChE;;AAEA;IACI,SAAO;IACP,kBAAkB;IAClB,YAAY;IACZ,6BAA6B;IAC7B,eAAe;IACf,eAAe;IACf,WAAW;IACX,6CAA6C;IAC7C,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;;AAE1B;;AAEA;IACI,SAAO;IACP,kBAAkB;IAClB,YAAY;IACZ,yBAAyB;IACzB,eAAe;IACf,eAAe;IACf,cAAc;IACd,6CAA6C;IAC7C,mBAAmB;AACvB","sourcesContent":[".test-switcher {\n    display: flex;\n    justify-content: center;\n    gap: 5px;\n    width: 100%;\n    margin-bottom: 20px;\n    background-color: #f0f0f0;\n    border-radius: 25px;\n    padding: 5px;\n    max-width: 500px;\n    margin: 20px auto; /* Center the toggle button on the page */\n}\n\n.test-button {\n    flex: 1;\n    padding: 10px 20px;\n    border: none;\n    background-color: transparent;\n    cursor: pointer;\n    font-size: 16px;\n    color: #555;\n    transition: background-color 0.3s, color 0.3s;\n    border-radius: 20px;\n}\n\n.test-button:hover {\n    background-color: #ddd;\n    \n}\n\n.test-button-active {\n    flex: 1;\n    padding: 10px 20px;\n    border: none;\n    background-color: #218CFF;\n    cursor: pointer;\n    font-size: 16px;\n    color: #ffffff;\n    transition: background-color 0.3s, color 0.3s;\n    border-radius: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"test-switcher": `TestSwitcher_test-switcher__BbEuC`,
	"test-button": `TestSwitcher_test-button__NCt7d`,
	"test-button-active": `TestSwitcher_test-button-active__r68GJ`
};
export default ___CSS_LOADER_EXPORT___;
