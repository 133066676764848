// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DigitalNetwork_digital-network__0kOpG {
    position: absolute;
    width: 1000px;
    z-index: 0; /* Ensure it's behind the text */
    top: 35%;
}

.DigitalNetwork_node__hStvt {
    fill: #FFFFFF;
    opacity: 1;
    transition: r 0.5s ease, transform 0.5s ease;
}

.DigitalNetwork_connection__rxDkl {
    stroke: #FFFFFF;
    stroke-width: 2;
    opacity: 0.7;
    stroke-dasharray: 400;
    stroke-dashoffset: 400;
    transition: stroke-dashoffset 2s ease;
}

.DigitalNetwork_loaded__7dgmg .DigitalNetwork_connection__rxDkl {
    stroke-dashoffset: 0;
}

/* Node movement animation */
@keyframes DigitalNetwork_pulse__QUOgq {
    0%, 100% {
        r: 5;
        transform: translate(0);
    }
    50% {
        r: 7;
        transform: translate(-2px, -2px);
    }
}

/* Adjusting connections to follow node movement */
@keyframes DigitalNetwork_float__dVNW3 {
    0%, 100% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(0, 5px);
    }
}

.DigitalNetwork_loaded__7dgmg .DigitalNetwork_node__hStvt {
    animation: DigitalNetwork_float__dVNW3 4s ease-in-out infinite alternate;
}

.DigitalNetwork_loaded__7dgmg .DigitalNetwork_connection__rxDkl { animation: DigitalNetwork_float__dVNW3 4s ease-in-out infinite alternate; }

@media (max-width: 1050px) {
    .DigitalNetwork_digital-network__0kOpG {
        display: none;
    }
}

/* Make the nodes slightly flicker */
@keyframes DigitalNetwork_flicker__yYmx7 {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/DigitalNetwork.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,UAAU,EAAE,gCAAgC;IAC5C,QAAQ;AACZ;;AAEA;IACI,aAAa;IACb,UAAU;IACV,4CAA4C;AAChD;;AAEA;IACI,eAAe;IACf,eAAe;IACf,YAAY;IACZ,qBAAqB;IACrB,sBAAsB;IACtB,qCAAqC;AACzC;;AAEA;IACI,oBAAoB;AACxB;;AAEA,4BAA4B;AAC5B;IACI;QACI,IAAI;QACJ,uBAAuB;IAC3B;IACA;QACI,IAAI;QACJ,gCAAgC;IACpC;AACJ;;AAEA,kDAAkD;AAClD;IACI;QACI,0BAA0B;IAC9B;IACA;QACI,4BAA4B;IAChC;AACJ;;AAEA;IACI,wEAAkD;AACtD;;AAEA,kEAAsB,wEAAkD,EAAE;;AAE1E;IACI;QACI,aAAa;IACjB;AACJ;;AAEA,oCAAoC;AACpC;IACI;QACI,UAAU;IACd;IACA;QACI,UAAU;IACd;AACJ","sourcesContent":[".digital-network {\n    position: absolute;\n    width: 1000px;\n    z-index: 0; /* Ensure it's behind the text */\n    top: 35%;\n}\n\n.node {\n    fill: #FFFFFF;\n    opacity: 1;\n    transition: r 0.5s ease, transform 0.5s ease;\n}\n\n.connection {\n    stroke: #FFFFFF;\n    stroke-width: 2;\n    opacity: 0.7;\n    stroke-dasharray: 400;\n    stroke-dashoffset: 400;\n    transition: stroke-dashoffset 2s ease;\n}\n\n.loaded .connection {\n    stroke-dashoffset: 0;\n}\n\n/* Node movement animation */\n@keyframes pulse {\n    0%, 100% {\n        r: 5;\n        transform: translate(0);\n    }\n    50% {\n        r: 7;\n        transform: translate(-2px, -2px);\n    }\n}\n\n/* Adjusting connections to follow node movement */\n@keyframes float {\n    0%, 100% {\n        transform: translate(0, 0);\n    }\n    50% {\n        transform: translate(0, 5px);\n    }\n}\n\n.loaded .node {\n    animation: float 4s ease-in-out infinite alternate;\n}\n\n.loaded .connection { animation: float 4s ease-in-out infinite alternate; }\n\n@media (max-width: 1050px) {\n    .digital-network {\n        display: none;\n    }\n}\n\n/* Make the nodes slightly flicker */\n@keyframes flicker {\n    0%, 100% {\n        opacity: 1;\n    }\n    50% {\n        opacity: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"digital-network": `DigitalNetwork_digital-network__0kOpG`,
	"node": `DigitalNetwork_node__hStvt`,
	"connection": `DigitalNetwork_connection__rxDkl`,
	"loaded": `DigitalNetwork_loaded__7dgmg`,
	"float": `DigitalNetwork_float__dVNW3`,
	"pulse": `DigitalNetwork_pulse__QUOgq`,
	"flicker": `DigitalNetwork_flicker__yYmx7`
};
export default ___CSS_LOADER_EXPORT___;
