// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/styles/IconButton.css */
.IconButton_icon-button__L\\+iTq {
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s, box-shadow 0.3s;
}

.IconButton_icon-button__L\\+iTq:hover {
    background-color: #f0f0f0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.IconButton_icon-button__L\\+iTq:focus {
    outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/styles/IconButton.module.css"],"names":[],"mappings":"AAAA,8BAA8B;AAC9B;IACI,uBAAuB;IACvB,yBAAyB;IACzB,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kDAAkD;AACtD;;AAEA;IACI,yBAAyB;IACzB,uCAAuC;AAC3C;;AAEA;IACI,aAAa;AACjB","sourcesContent":["/* src/styles/IconButton.css */\n.icon-button {\n    background-color: white;\n    border: 1px solid #e0e0e0;\n    border-radius: 50%;\n    width: 40px;\n    height: 40px;\n    padding: 10px;\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    transition: background-color 0.3s, box-shadow 0.3s;\n}\n\n.icon-button:hover {\n    background-color: #f0f0f0;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n}\n\n.icon-button:focus {\n    outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon-button": `IconButton_icon-button__L+iTq`
};
export default ___CSS_LOADER_EXPORT___;
