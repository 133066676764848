import React, { useState } from "react";
import styles from "../styles/ResetPasswordPage.module.css";

function ResetPassword() {
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');
    const [mailError, setMailError] = useState(false);
    const [codeError, setCodeError] = useState(false);
    const [codeSent, setCodeSent] = useState(false);

    const handleResetPassword = (e) => {
        e.preventDefault();
        
        fetch('https://dev.api.wanteed-job.com/credential/reset-password', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: email, code: code, password: password }),
        })
            .then(response => {
                if (response.status !== 200) {
                    setCodeError(true);
                    return;
                }
                setCodeError(false);
            }).then(data => {
                console.log('Success:', data);
            });
    }

    const handleSendCode = (e) => {
        e.preventDefault();

        fetch(`https://dev.api.wanteed-job.com/credential/send-reset-password-code?email=${email}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (response.status !== 200) {
                    setMailError(true);
                    return;
                }
                setMailError(false);
                setCodeSent(true);
            }).then(data => {
                console.log('Success:', data);
            });
    }

    return (
        <div className={styles["resetPage"]}>
            <h1>Réinitialiser votre mot de passe</h1>
            {!codeSent ? (
                <div className={styles["resetCard"]}>
                    <p>Cliquez sur le bouton ci-dessous pour recevoir un code de validation par email.</p>
                    <div className={styles["resetForm"]}>
                        <form onSubmit={handleSendCode}>
                            <input
                                type="email"
                                id="email"
                                name="email_value"
                                placeholder="Entrez votre email"
                                value={email}
                                className={mailError ? styles["error"] : ''}
                                onChange={(e) => {setEmail(e.target.value); setMailError(false)}}
                                style={{ margin: '0' }}
                            />
                        </form>
                    </div>
                    <button type="submit" onClick={handleSendCode}>Envoyer le code</button>
                </div>
            ) : (
                <div className={styles["resetCard"]}>
                    <p>Un code de validation vous a été envoyé par email à l'adresse suivante : {email}</p>
                    <p>Entrez le code de validation reçu par email et votre nouveau mot de passe.</p>
                    <div className={styles["resetForm"]}>
                        <form onSubmit={handleResetPassword}>
                            <input
                                type="text"
                                id="reset_code"
                                name="reset_code"
                                placeholder="Entrez le code de validation"
                                value={code}
                                className={codeError ? styles["error"] : ''}
                                onChange={(e) => {setCode(e.target.value); setCodeError(false)}}
                            />
                            <input
                                type="password"
                                id="password"
                                name="password"
                                placeholder="Entrez votre nouveau mot de passe"
                                value={password}
                                onChange={(e) => {setPassword(e.target.value); setCodeError(false)}}
                                style={{ margin: '0' }}
                            />
                            <div className={styles["inputRow"]}>
                                <button type="submit">Valider</button>
                            </div>
                        </form>
                        <p>Renvoyer le code de validation <span onClick={() => {handleSendCode()}} className={styles["linkSpan"]}>ici</span></p>
                    </div>
                </div>
            )}
            <p>Vous avez déjà un compte ? <a href="/login">Connectez-vous</a></p>
        </div >
    );
}

export default ResetPassword;