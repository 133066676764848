// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ExperiencePage_experience-page__3G7Nw {
    margin: 50px auto;
    background-color: #fff;
    border-radius: 10px;
    text-align: center;
    width: 85%;
}

.ExperiencePage_experience-page__3G7Nw h1 {
    margin-bottom: 50px;
}`, "",{"version":3,"sources":["webpack://./src/styles/ExperiencePage.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".experience-page {\n    margin: 50px auto;\n    background-color: #fff;\n    border-radius: 10px;\n    text-align: center;\n    width: 85%;\n}\n\n.experience-page h1 {\n    margin-bottom: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"experience-page": `ExperiencePage_experience-page__3G7Nw`
};
export default ___CSS_LOADER_EXPORT___;
