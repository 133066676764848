// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HomePage_home-page__6S7dT {
    background-color: #218CFF;
}`, "",{"version":3,"sources":["webpack://./src/styles/HomePage.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B","sourcesContent":[".home-page {\n    background-color: #218CFF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home-page": `HomePage_home-page__6S7dT`
};
export default ___CSS_LOADER_EXPORT___;
