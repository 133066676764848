// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/styles/Modal.css */
.ExperienceModal_modal-overlay__6cVQt {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    height: 100%
}

.ExperienceModal_modal-content__Tyy85 {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 800px;
    width: 100%;
    position: relative;
}

.ExperienceModal_modal-content__Tyy85 h2 {
    text-align: center;
    margin: 30px 0 0 0 !important;
}

.ExperienceModal_modal-close__HystQ {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    font-size: 24px;
    cursor: pointer;
    color: #218CFF;
}

.ExperienceModal_modal-close__HystQ:hover {
    color: #218CFF;
    background: #218cff51;
}

.ExperienceModal_close-button__QO\\+6g {
    border-radius: 50%;
    width: 30px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333;
    margin-bottom: 10px;
    color: #218CFF;
}

`, "",{"version":3,"sources":["webpack://./src/styles/ExperienceModal.module.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb;AACJ;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,kBAAkB;IAClB,gBAAgB;IAChB,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,6BAA6B;AACjC;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,gBAAgB;IAChB,eAAe;IACf,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,mBAAmB;IACnB,cAAc;AAClB","sourcesContent":["/* src/styles/Modal.css */\r\n.modal-overlay {\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    right: 0;\r\n    bottom: 0;\r\n    background: rgba(0, 0, 0, 0.7);\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    z-index: 1000;\r\n    height: 100%\r\n}\r\n\r\n.modal-content {\r\n    background: white;\r\n    padding: 20px;\r\n    border-radius: 8px;\r\n    max-width: 800px;\r\n    width: 100%;\r\n    position: relative;\r\n}\r\n\r\n.modal-content h2 {\r\n    text-align: center;\r\n    margin: 30px 0 0 0 !important;\r\n}\r\n\r\n.modal-close {\r\n    position: absolute;\r\n    top: 10px;\r\n    right: 10px;\r\n    background: none;\r\n    font-size: 24px;\r\n    cursor: pointer;\r\n    color: #218CFF;\r\n}\r\n\r\n.modal-close:hover {\r\n    color: #218CFF;\r\n    background: #218cff51;\r\n}\r\n\r\n.close-button {\r\n    border-radius: 50%;\r\n    width: 30px;\r\n    height: 40px;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    background-color: #333;\r\n    margin-bottom: 10px;\r\n    color: #218CFF;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal-overlay": `ExperienceModal_modal-overlay__6cVQt`,
	"modal-content": `ExperienceModal_modal-content__Tyy85`,
	"modal-close": `ExperienceModal_modal-close__HystQ`,
	"close-button": `ExperienceModal_close-button__QO+6g`
};
export default ___CSS_LOADER_EXPORT___;
