// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ToggleButton_toggle-button__2EPba {
    display: flex;
    justify-content: center;
    gap: 5px;
    width: 100%;
    margin-bottom: 20px;
    background-color: #f0f0f0;
    border-radius: 25px;
    padding: 5px;
    max-width: 800px;
    margin: 20px auto; /* Center the toggle button on the page */
}

.ToggleButton_toggle-button__2EPba button {
    flex: 1 1;
    padding: 10px 20px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 16px;
    color: #555;
    transition: background-color 0.3s, color 0.3s;
    border-radius: 20px;
}

.ToggleButton_toggle-button__2EPba button.ToggleButton_active__kbaUU {
    background-color: #218CFF;
    color: #fff;
}

.ToggleButton_toggle-button__2EPba button:not(.ToggleButton_active__kbaUU):hover {
    background-color: #ddd;
    color: #000;
}
`, "",{"version":3,"sources":["webpack://./src/styles/ToggleButton.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,QAAQ;IACR,WAAW;IACX,mBAAmB;IACnB,yBAAyB;IACzB,mBAAmB;IACnB,YAAY;IACZ,gBAAgB;IAChB,iBAAiB,EAAE,yCAAyC;AAChE;;AAEA;IACI,SAAO;IACP,kBAAkB;IAClB,YAAY;IACZ,6BAA6B;IAC7B,eAAe;IACf,eAAe;IACf,WAAW;IACX,6CAA6C;IAC7C,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,sBAAsB;IACtB,WAAW;AACf","sourcesContent":[".toggle-button {\n    display: flex;\n    justify-content: center;\n    gap: 5px;\n    width: 100%;\n    margin-bottom: 20px;\n    background-color: #f0f0f0;\n    border-radius: 25px;\n    padding: 5px;\n    max-width: 800px;\n    margin: 20px auto; /* Center the toggle button on the page */\n}\n\n.toggle-button button {\n    flex: 1;\n    padding: 10px 20px;\n    border: none;\n    background-color: transparent;\n    cursor: pointer;\n    font-size: 16px;\n    color: #555;\n    transition: background-color 0.3s, color 0.3s;\n    border-radius: 20px;\n}\n\n.toggle-button button.active {\n    background-color: #218CFF;\n    color: #fff;\n}\n\n.toggle-button button:not(.active):hover {\n    background-color: #ddd;\n    color: #000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggle-button": `ToggleButton_toggle-button__2EPba`,
	"active": `ToggleButton_active__kbaUU`
};
export default ___CSS_LOADER_EXPORT___;
