// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/styles/Modal.css */
.Modal_modal-overlay__2smzf {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    height: 100%
}

.Modal_modal-content__C6S17 {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    position: relative;

}

.Modal_modal-close__G7\\+Ln {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    font-size: 24px;
    cursor: pointer;
    color: #218CFF;
}

.Modal_modal-close__G7\\+Ln:hover {
    color: #218CFF;
    background: #218cff51;
}

.Modal_close-button__Tb7nl {
    border-radius: 50%;
    width: 30px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333;
    margin-bottom: 10px;
    color: #218CFF;
}`, "",{"version":3,"sources":["webpack://./src/styles/Modal.module.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb;AACJ;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,kBAAkB;IAClB,gBAAgB;IAChB,WAAW;IACX,kBAAkB;;AAEtB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,gBAAgB;IAChB,eAAe;IACf,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,mBAAmB;IACnB,cAAc;AAClB","sourcesContent":["/* src/styles/Modal.css */\n.modal-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background: rgba(0, 0, 0, 0.7);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000;\n    height: 100%\n}\n\n.modal-content {\n    background: white;\n    padding: 20px;\n    border-radius: 8px;\n    max-width: 500px;\n    width: 100%;\n    position: relative;\n\n}\n\n.modal-close {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    background: none;\n    font-size: 24px;\n    cursor: pointer;\n    color: #218CFF;\n}\n\n.modal-close:hover {\n    color: #218CFF;\n    background: #218cff51;\n}\n\n.close-button {\n    border-radius: 50%;\n    width: 30px;\n    height: 40px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: #333;\n    margin-bottom: 10px;\n    color: #218CFF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal-overlay": `Modal_modal-overlay__2smzf`,
	"modal-content": `Modal_modal-content__C6S17`,
	"modal-close": `Modal_modal-close__G7+Ln`,
	"close-button": `Modal_close-button__Tb7nl`
};
export default ___CSS_LOADER_EXPORT___;
