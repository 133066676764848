// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GenericToggleButton_toggle-button__PNUuB {
    display: flex;
    justify-content: center;
    gap: 5px;
    margin-bottom: 20px;
    background-color: #f0f0f0;
    border-radius: 25px;
    padding: 5px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    box-sizing: border-box;
    max-width: 800px;
}

.GenericToggleButton_toggle-button__PNUuB button {
    flex: 1 1;
    padding: 10px 20px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 16px;
    color: #555;
    transition: background-color 0.3s, color 0.3s;
    border-radius: 20px;
    box-sizing: border-box;
}

.GenericToggleButton_toggle-button__PNUuB button.GenericToggleButton_active__uwxr\\+ {
    background-color: #218CFF;
    color: #fff;
}

.GenericToggleButton_toggle-button__PNUuB button:not(.GenericToggleButton_active__uwxr\\+):hover {
    background-color: #ddd;
    color: #000;
}

@media screen and (max-width: 768px) {
    .GenericToggleButton_toggle-button__PNUuB {
        flex-direction: column;
        width: auto;
        border-radius: 10px;
        padding: 10px;
        margin: 0 auto;
        align-items: center;
    }

    .GenericToggleButton_toggle-button__PNUuB button {
        flex: 0 0 100%;
        padding: 15px;
        font-size: 18px;
    }

    .GenericToggleButton_toggle-button__PNUuB button:not(.GenericToggleButton_active__uwxr\\+):hover {
        background-color: transparent;
        color: #555;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/GenericToggleButton.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,QAAQ;IACR,mBAAmB;IACnB,yBAAyB;IACzB,mBAAmB;IACnB,YAAY;IACZ,cAAc;IACd,gBAAgB;IAChB,mBAAmB;IACnB,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,SAAO;IACP,kBAAkB;IAClB,YAAY;IACZ,6BAA6B;IAC7B,eAAe;IACf,eAAe;IACf,WAAW;IACX,6CAA6C;IAC7C,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI;QACI,sBAAsB;QACtB,WAAW;QACX,mBAAmB;QACnB,aAAa;QACb,cAAc;QACd,mBAAmB;IACvB;;IAEA;QACI,cAAc;QACd,aAAa;QACb,eAAe;IACnB;;IAEA;QACI,6BAA6B;QAC7B,WAAW;IACf;AACJ","sourcesContent":[".toggle-button {\n    display: flex;\n    justify-content: center;\n    gap: 5px;\n    margin-bottom: 20px;\n    background-color: #f0f0f0;\n    border-radius: 25px;\n    padding: 5px;\n    margin: 0 auto;\n    margin-top: 20px;\n    margin-bottom: 20px;\n    box-sizing: border-box;\n    max-width: 800px;\n}\n\n.toggle-button button {\n    flex: 1;\n    padding: 10px 20px;\n    border: none;\n    background-color: transparent;\n    cursor: pointer;\n    font-size: 16px;\n    color: #555;\n    transition: background-color 0.3s, color 0.3s;\n    border-radius: 20px;\n    box-sizing: border-box;\n}\n\n.toggle-button button.active {\n    background-color: #218CFF;\n    color: #fff;\n}\n\n.toggle-button button:not(.active):hover {\n    background-color: #ddd;\n    color: #000;\n}\n\n@media screen and (max-width: 768px) {\n    .toggle-button {\n        flex-direction: column;\n        width: auto;\n        border-radius: 10px;\n        padding: 10px;\n        margin: 0 auto;\n        align-items: center;\n    }\n\n    .toggle-button button {\n        flex: 0 0 100%;\n        padding: 15px;\n        font-size: 18px;\n    }\n\n    .toggle-button button:not(.active):hover {\n        background-color: transparent;\n        color: #555;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggle-button": `GenericToggleButton_toggle-button__PNUuB`,
	"active": `GenericToggleButton_active__uwxr+`
};
export default ___CSS_LOADER_EXPORT___;
