// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/styles/Intro.css */

.Invite_intro__hQMKP {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.Invite_two-cards__Kz-Qb {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}

.Invite_instructions__RYcWF {
    text-align: center;
    margin-bottom: 20px;
    border: 1px solid #007bff;
    padding: 10px;
    border-radius: 10px;
    margin-top: 20px;
    width: 80%;
}

.Invite_offer-details__Q7oeP {
    text-align: center;
    width: 50%;
}   

.Invite_login__\\+nwHm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

ul {
    text-align: left;
}

button {
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;
    background-color: #218CFF;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: max-content;
}

button:hover {
    background-color: #0056b3;
}`, "",{"version":3,"sources":["webpack://./src/styles/Invite.module.css"],"names":[],"mappings":"AAAA,yBAAyB;;AAEzB;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,WAAW;IACX,yBAAyB;IACzB,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,iCAAiC;IACjC,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["/* src/styles/Intro.css */\n\n.intro {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    padding: 20px;\n    max-width: 1200px;\n    margin: 0 auto;\n}\n\n.two-cards {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n    margin-top: 20px;\n}\n\n.instructions {\n    text-align: center;\n    margin-bottom: 20px;\n    border: 1px solid #007bff;\n    padding: 10px;\n    border-radius: 10px;\n    margin-top: 20px;\n    width: 80%;\n}\n\n.offer-details {\n    text-align: center;\n    width: 50%;\n}   \n\n.login {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    padding: 20px;\n    max-width: 1200px;\n    margin: 0 auto;\n}\n\nul {\n    text-align: left;\n}\n\nbutton {\n    padding: 10px 20px;\n    font-size: 1em;\n    color: #fff;\n    background-color: #218CFF;\n    border: none;\n    border-radius: 10px;\n    cursor: pointer;\n    transition: background-color 0.3s;\n    width: max-content;\n}\n\nbutton:hover {\n    background-color: #0056b3;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"intro": `Invite_intro__hQMKP`,
	"two-cards": `Invite_two-cards__Kz-Qb`,
	"instructions": `Invite_instructions__RYcWF`,
	"offer-details": `Invite_offer-details__Q7oeP`,
	"login": `Invite_login__+nwHm`
};
export default ___CSS_LOADER_EXPORT___;
