// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/styles/Register.css */
.ResetPasswordPage_resetPage__Mj9wd {
    padding: 20px;
    width: 60%;
    margin: 50px auto;
    text-align: center;
}

.ResetPasswordPage_resetCard__m\\+UkF {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.ResetPasswordPage_resetForm__h16Yh {
    display: flex;  
    flex-direction: column;
    align-items: center;
    height: 80%;
}

.ResetPasswordPage_resetForm__h16Yh form {
    background-color: transparent;
    box-shadow: none;
    margin: 0 50px;
    width: 100%;
}

.ResetPasswordPage_resetForm__h16Yh input {
    margin: 0;
    width: 100%;
}

.ResetPasswordPage_resetCard__m\\+UkF h1 {
    margin-bottom: 20px;
}

.ResetPasswordPage_resetCard__m\\+UkF p {
    margin-bottom: 20px;
}

.ResetPasswordPage_resetForm__h16Yh .ResetPasswordPage_error__adxLy {
    border: 1px solid red;
    color: red;
}

.ResetPasswordPage_resetCard__m\\+UkF button {
    width: 100%;
    margin-top: 30px;
    padding: 10px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #0056b3;
}

.ResetPasswordPage_linkSpan__ry9n7 {
    color: #007BFF;
    text-decoration: none;
    cursor: pointer;
}

.ResetPasswordPage_linkSpan__ry9n7:hover {
    text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/styles/ResetPasswordPage.module.css"],"names":[],"mappings":"AAAA,4BAA4B;AAC5B;IACI,aAAa;IACb,UAAU;IACV,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,uBAAuB;IACvB,aAAa;IACb,mBAAmB;IACnB,uCAAuC;AAC3C;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,6BAA6B;IAC7B,gBAAgB;IAChB,cAAc;IACd,WAAW;AACf;;AAEA;IACI,SAAS;IACT,WAAW;AACf;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;IACrB,UAAU;AACd;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,iCAAiC;AACrC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,cAAc;IACd,qBAAqB;IACrB,eAAe;AACnB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":["/* src/styles/Register.css */\n.resetPage {\n    padding: 20px;\n    width: 60%;\n    margin: 50px auto;\n    text-align: center;\n}\n\n.resetCard {\n    background-color: white;\n    padding: 20px;\n    border-radius: 10px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n}\n\n.resetForm {\n    display: flex;  \n    flex-direction: column;\n    align-items: center;\n    height: 80%;\n}\n\n.resetForm form {\n    background-color: transparent;\n    box-shadow: none;\n    margin: 0 50px;\n    width: 100%;\n}\n\n.resetForm input {\n    margin: 0;\n    width: 100%;\n}\n\n.resetCard h1 {\n    margin-bottom: 20px;\n}\n\n.resetCard p {\n    margin-bottom: 20px;\n}\n\n.resetForm .error {\n    border: 1px solid red;\n    color: red;\n}\n\n.resetCard button {\n    width: 100%;\n    margin-top: 30px;\n    padding: 10px;\n    background-color: #007BFF;\n    color: white;\n    border: none;\n    border-radius: 10px;\n    cursor: pointer;\n    transition: background-color 0.3s;\n}\n\nbutton:hover {\n    background-color: #0056b3;\n}\n\n.linkSpan {\n    color: #007BFF;\n    text-decoration: none;\n    cursor: pointer;\n}\n\n.linkSpan:hover {\n    text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resetPage": `ResetPasswordPage_resetPage__Mj9wd`,
	"resetCard": `ResetPasswordPage_resetCard__m+UkF`,
	"resetForm": `ResetPasswordPage_resetForm__h16Yh`,
	"error": `ResetPasswordPage_error__adxLy`,
	"linkSpan": `ResetPasswordPage_linkSpan__ry9n7`
};
export default ___CSS_LOADER_EXPORT___;
