// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/styles/CookieConsent.css */
.CookieConsent_cookie-consent-popup__07UOB {
    position: fixed;
    bottom: 0;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    z-index: 1000;
}

.CookieConsent_cookie-consent-text__q\\+H6o {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 0px;
    padding-left: 20px;
}

.CookieConsent_cookie-consent-text__q\\+H6o h2 {
    margin-top: 0;
    font-size: 16px;
    color: #333;
}

.CookieConsent_cookie-consent-text__q\\+H6o p {
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
    margin-top: 0;
}

.CookieConsent_cookie-consent-content__uNczO {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.CookieConsent_cookie-consent-button__a2wsu {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 14px;
    margin: 0 15px;
}

.CookieConsent_cookie-consent-button__a2wsu:hover {
    background-color: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/styles/CookieConsent.module.css"],"names":[],"mappings":"AAAA,iCAAiC;AACjC;IACI,eAAe;IACf,SAAS;IACT,yBAAyB;IACzB,sBAAsB;IACtB,2CAA2C;IAC3C,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,WAAW;AACf;;AAEA;IACI,eAAe;IACf,WAAW;IACX,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,iBAAiB;IACjB,eAAe;IACf,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["/* src/styles/CookieConsent.css */\n.cookie-consent-popup {\n    position: fixed;\n    bottom: 0;\n    background-color: #f5f5f5;\n    border: 1px solid #ccc;\n    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);\n    width: 100%;\n    z-index: 1000;\n}\n\n.cookie-consent-text {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    text-align: center;\n    padding-top: 0px;\n    padding-left: 20px;\n}\n\n.cookie-consent-text h2 {\n    margin-top: 0;\n    font-size: 16px;\n    color: #333;\n}\n\n.cookie-consent-text p {\n    font-size: 14px;\n    color: #666;\n    margin-bottom: 10px;\n    margin-top: 0;\n}\n\n.cookie-consent-content {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-top: 20px;\n}\n\n.cookie-consent-button {\n    background-color: #007bff;\n    color: white;\n    border: none;\n    border-radius: 10px;\n    padding: 8px 16px;\n    cursor: pointer;\n    font-size: 14px;\n    margin: 0 15px;\n}\n\n.cookie-consent-button:hover {\n    background-color: #0056b3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cookie-consent-popup": `CookieConsent_cookie-consent-popup__07UOB`,
	"cookie-consent-text": `CookieConsent_cookie-consent-text__q+H6o`,
	"cookie-consent-content": `CookieConsent_cookie-consent-content__uNczO`,
	"cookie-consent-button": `CookieConsent_cookie-consent-button__a2wsu`
};
export default ___CSS_LOADER_EXPORT___;
