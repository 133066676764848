// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PrivacyPolicy_privacy-policy-container__hQRn4 {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    line-height: 1.6;
    color: #333;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.PrivacyPolicy_privacy-policy-container__hQRn4 h1, 
.PrivacyPolicy_privacy-policy-container__hQRn4 h2 {
    color: #1a1a1a;
}

.PrivacyPolicy_privacy-policy-container__hQRn4 ul {
    list-style-type: disc;
    margin-left: 20px;
}

.PrivacyPolicy_privacy-policy-container__hQRn4 p {
    margin-bottom: 20px;
}

.PrivacyPolicy_privacy-policy-container__hQRn4 li {
    margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/PrivacyPolicy.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,gBAAgB;IAChB,WAAW;IACX,sBAAsB;IACtB,kBAAkB;IAClB,2CAA2C;AAC/C;;AAEA;;IAEI,cAAc;AAClB;;AAEA;IACI,qBAAqB;IACrB,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".privacy-policy-container {\n    max-width: 800px;\n    margin: 0 auto;\n    padding: 20px;\n    line-height: 1.6;\n    color: #333;\n    background-color: #fff;\n    border-radius: 8px;\n    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);\n}\n\n.privacy-policy-container h1, \n.privacy-policy-container h2 {\n    color: #1a1a1a;\n}\n\n.privacy-policy-container ul {\n    list-style-type: disc;\n    margin-left: 20px;\n}\n\n.privacy-policy-container p {\n    margin-bottom: 20px;\n}\n\n.privacy-policy-container li {\n    margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"privacy-policy-container": `PrivacyPolicy_privacy-policy-container__hQRn4`
};
export default ___CSS_LOADER_EXPORT___;
