// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Offer_offerContainer__RD4DL {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    width: 100%;
    margin: 20px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
}

.Offer_title__BFTKp {
    font-size: 24px;
    color: #333;
    margin-bottom: 40px;
}

.Offer_section-h__3vosb {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 40px;
}

.Offer_section-v__tBi30 {
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.Offer_offerContainer__RD4DL p {
    font-size: 16px;
    color: #666;
    margin: 5px;
    text-align: left;
}

.Offer_offerContainer__RD4DL strong {
    font-size: 1.2rem;
    color: #333;
}

.Offer_description__6b9dR p {
    font-size: 14px;
}

.Offer_activate-button__1b6ul {
    font-size: 16px;
    height: 30px;
    width: 60px;
    margin-left: 20px;
    background-color: white !important;
    border: 2px solid #218CFF;
    font-weight: bold;
    box-sizing: border-box;
    padding: 0 10px; /* Optionnel : ajustez si vous voulez un peu de marge à gauche et à droite */
}

.Offer_activate-button__1b6ul:hover {
    border-width: 4px;
}`, "",{"version":3,"sources":["webpack://./src/styles/Offer.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,WAAW;IACX,iBAAiB;IACjB,wCAAwC;IACxC,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,WAAW;IACX,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,WAAW;IACX,iBAAiB;IACjB,kCAAkC;IAClC,yBAAyB;IACzB,iBAAiB;IACjB,sBAAsB;IACtB,eAAe,EAAE,4EAA4E;AACjG;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".offerContainer {\n    padding: 20px;\n    background-color: #ffffff;\n    border-radius: 10px;\n    width: 100%;\n    margin: 20px auto;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    box-sizing: border-box;\n}\n\n.title {\n    font-size: 24px;\n    color: #333;\n    margin-bottom: 40px;\n}\n\n.section-h {\n    display: flex;\n    justify-content: space-evenly;\n    margin-bottom: 40px;\n}\n\n.section-v {\n    display: flex;\n    flex-direction: column;\n    justify-content: start;\n}\n\n.offerContainer p {\n    font-size: 16px;\n    color: #666;\n    margin: 5px;\n    text-align: left;\n}\n\n.offerContainer strong {\n    font-size: 1.2rem;\n    color: #333;\n}\n\n.description p {\n    font-size: 14px;\n}\n\n.activate-button {\n    font-size: 16px;\n    height: 30px;\n    width: 60px;\n    margin-left: 20px;\n    background-color: white !important;\n    border: 2px solid #218CFF;\n    font-weight: bold;\n    box-sizing: border-box;\n    padding: 0 10px; /* Optionnel : ajustez si vous voulez un peu de marge à gauche et à droite */\n}\n\n.activate-button:hover {\n    border-width: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"offerContainer": `Offer_offerContainer__RD4DL`,
	"title": `Offer_title__BFTKp`,
	"section-h": `Offer_section-h__3vosb`,
	"section-v": `Offer_section-v__tBi30`,
	"description": `Offer_description__6b9dR`,
	"activate-button": `Offer_activate-button__1b6ul`
};
export default ___CSS_LOADER_EXPORT___;
